import { logger } from "@nanostores/logger";
import { persistentAtom } from "@nanostores/persistent";
import SuperJSON from "superjson";
import type {
	AuthenticationResponse,
	UserLoginRequest,
	ViewBusinessUnitResponse,
} from "./api/AuthenticationApi";
import { authenticationApi } from "./helpers";

type Auth = {
	businessUnit?: ViewBusinessUnitResponse;
	user?: AuthenticationResponse;
};

export const authStore = persistentAtom<Auth>(
	"auth",
	{},
	{
		encode: SuperJSON.stringify,
		decode: SuperJSON.parse,
	},
);
logger({ authStore });

export const authStoreLogin = async (params: UserLoginRequest) => {
	const res =
		await authenticationApi.authentication.authenticationCreate(params);
	authStore.set({ user: res.data });
	return res;
};

export const authStoreSelectBusinessUnit = async (id: string) => {
	const res = await authenticationApi.businessUnit.businessUnitDetail(id);
	authStore.set({ ...authStore.value, businessUnit: res.data });
	return res;
};
