import styled from "styled-components";

type FlexProps = {
	gap?: string;
	flexDirection?: "row" | "column";
};
export const Flex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${({ flexDirection = "row" }) => flexDirection};
  gap: ${({ gap = "20px" }) => gap};
  > * {
    flex: 1;
  }
`;
Flex.shouldForwardProp = (prop) => prop !== "gap" && prop !== "flexDirection";
