import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useNotifyUpdate, useTracker } from "../new/helpers";
import { HomePage } from "./DriverHomePage";
import { ForgotPasswordPage } from "./ForgotPasswordPage";
import { LoginPage } from "./LoginPage";
import { ResetPasswordPage } from "./ResetPasswordPage";

export const App = () => {
	useTracker();
	useNotifyUpdate();
	return (
		<div style={{ touchAction: "pan-x pan-y" }}>
			<ToastContainer />
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/login" element={<LoginPage />} />
				<Route path="/forgotten-password" element={<ForgotPasswordPage />} />
				<Route path="/reset-password" element={<ResetPasswordPage />} />
			</Routes>
		</div>
	);
};
