import { useNavigate, useSearchParams } from "react-router-dom";
import { useTitle } from "react-use";
import { ResetPasswordForm } from "../new/ResetPasswordForm";
import { authenticationApi } from "../new/helpers";
import { DriverLayout } from "./DriverLayout";

export const ResetPasswordPage = () => {
	useTitle("Reset Password");
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const token = searchParams.get("token")?.split(" ").join("+");
	const email = searchParams.get("email");
	if (!token || !email) return "Invalid URL.";
	return (
		<DriverLayout>
			<ResetPasswordForm
				onSubmit={async (x) => {
					try {
						await authenticationApi.authentication.authenticationResetPasswordCreate(
							{
								email: email,
								token: token,
								password: x.password,
							},
						);
					} catch (error) {
						alert((error as Error)?.message ?? error);
						return;
					}
					navigate("/login");
				}}
			/>
		</DriverLayout>
	);
};
