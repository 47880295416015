import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { type InferType, object, string } from "yup";
import { InputText } from "./GenericForm";

const ForgottenPasswordSchema = object({
	email: string().email().required().label("Email"),
});

type ForgottenPassword = InferType<typeof ForgottenPasswordSchema>;

type ForgottenPasswordFormProps = {
	email?: string;
	onSubmit: (data: ForgottenPassword) => Promise<unknown>;
};
export const ForgottenPasswordForm = ({
	email = "",
	onSubmit,
}: ForgottenPasswordFormProps) => {
	const [loading, setLoading] = useState(false);
	const { handleSubmit, control } = useForm<ForgottenPassword>({
		resolver: yupResolver(ForgottenPasswordSchema),
		defaultValues: { email },
	});
	const navigate = useNavigate();
	return (
		<form
			className="k-form"
			onSubmit={handleSubmit(async (x) => {
				setLoading(true);
				await onSubmit(x).finally(() => setLoading(false));
			})}
		>
			<InputText
				control={control}
				schema={ForgottenPasswordSchema}
				name="email"
			/>
			<p style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
				<Button
					type="button"
					themeColor="secondary"
					onClick={() => navigate("/login")}
				>
					Go back to login
				</Button>
				<Button
					type="submit"
					themeColor="success"
					icon={loading ? "loading" : undefined}
					disabled={loading}
				>
					Email me a reset link
				</Button>
			</p>
		</form>
	);
};
