import { Button } from "@progress/kendo-react-buttons";
import { useLocation, useNavigate } from "react-router-dom";
import { useTitle } from "react-use";
import { ForgottenPasswordForm } from "../new/ForgottenPasswordForm";
import { authenticationApi } from "../new/helpers";
import { useDialog } from "../new/helpersReact";
import { DriverLayout } from "./DriverLayout";

const Dialog = () => {
	const navigate = useNavigate();
	return (
		<>
			If you are configured in the system, <br />
			an email has been sent with a link to reset your password. <br />
			Please click the link when you get it.
			<br />
			<br />
			<Button onClick={() => navigate("/login")}>Go back to login</Button>
		</>
	);
};

export const ForgotPasswordPage = () => {
	useTitle("Forgotten Password");
	const location = useLocation();
	const email = location.state?.email as string | undefined;
	const [showDialog, dialog] = useDialog(<Dialog />, "Forgotten Password");
	return (
		<DriverLayout>
			{dialog}
			<ForgottenPasswordForm
				email={email}
				onSubmit={async (x) => {
					await authenticationApi.authentication
						.authenticationForgotPasswordCreate(x)
						.finally(showDialog);
				}}
			/>
		</DriverLayout>
	);
};
