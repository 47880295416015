import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { type InferType, object, ref, string } from "yup";
import { InputPassword } from "./GenericForm";

const ResetPasswordSchema = object({
	password: string()
		.test({
			name: "lowercase",
			message: "Password must contain at least one lowercase letter",
			test: (value) => /[a-z]/.test(value ?? ""),
		})
		.test({
			name: "uppercase",
			message: "Password must contain at least one uppercase letter",
			test: (value) => /[A-Z]/.test(value ?? ""),
		})
		.test({
			name: "number",
			message: "Password must contain at least one number",
			test: (value) => /\d/.test(value ?? ""),
		})
		.test({
			name: "special",
			message: "Password must contain at least one special character (!@$%^&*)",
			test: (value) => /[!@$%^&*]/.test(value ?? ""),
		})
		.test({
			name: "not-these-special",
			message:
				"Password must not contain the following characters £ # “” or spaces",
			test: (value) => !/[£#“”\s]/.test(value ?? ""),
		})
		.min(8, "Password must be at least 8 characters")
		.required()
		.label("Password"),
	confirmPassword: string()
		.required()
		.oneOf([ref("password")], "Passwords must match")
		.label("Confirm Password"),
});

type ResetPassword = InferType<typeof ResetPasswordSchema>;

type ResetPasswordFormProps = {
	onSubmit: (data: ResetPassword) => Promise<unknown>;
};
export const ResetPasswordForm = ({ onSubmit }: ResetPasswordFormProps) => {
	const [loading, setLoading] = useState(false);
	const { handleSubmit, control } = useForm<ResetPassword>({
		resolver: yupResolver(ResetPasswordSchema),
		defaultValues: { password: "", confirmPassword: "" },
	});
	return (
		<form
			className="k-form"
			onSubmit={handleSubmit(async (x) => {
				setLoading(true);
				await onSubmit(x).finally(() => setLoading(false));
			})}
		>
			<InputPassword
				control={control}
				schema={ResetPasswordSchema}
				name="password"
			/>
			<InputPassword
				control={control}
				schema={ResetPasswordSchema}
				name="confirmPassword"
			/>
			<p style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
				<Button
					type="submit"
					themeColor="success"
					icon={loading ? "loading" : undefined}
					disabled={loading}
				>
					Reset Password
				</Button>
			</p>
		</form>
	);
};
