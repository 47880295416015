import { useStore } from "@nanostores/react";
import { Reveal } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import { Icon } from "@progress/kendo-react-common";
import { Input } from "@progress/kendo-react-inputs";
import {
	ExpansionPanel,
	ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import {
	type CSSProperties,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useNavigate } from "react-router-dom";
import SignaturePad from "react-signature-pad-wrapper";
import { toast } from "react-toastify";
import { useTitle } from "react-use";
import styled from "styled-components";
import { Flex } from "../new/GenericFlex";
import type { ViewLegsForDriverResponse } from "../new/api/JobApi";
import { authStore } from "../new/authStore";
import {
	DocumentUsage,
	LegStatusType,
	jobApi,
	legStatusTypeNames,
	toDatetimeString,
	toasted,
	uploadDocuments,
} from "../new/helpers";
import { useDialog } from "../new/helpersReact";

const StyledDiv = styled.div`
	display: grid;
	grid-template-rows: auto 1fr;
	height: 100vh;
	box-sizing: border-box;
	> div {
		margin: 0 20px;
	}
`;

const useMenuDialog = () => {
	const navigate = useNavigate();
	const [toggleMenuDialog, menuDialog] = useDialog(
		<Flex flexDirection="column">
			<Button
				size="large"
				themeColor="primary"
				style={{ width: "100%" }}
				onClick={async () => {
					localStorage.clear();
					navigate("/login");
				}}
			>
				Log out
			</Button>
			<Button
				size="large"
				themeColor="primary"
				style={{ width: "100%" }}
				onClick={() => navigate("/forgotten-password")}
			>
				Forgot password
			</Button>
			<Button
				size="large"
				themeColor="secondary"
				style={{ width: "100%" }}
				onClick={() => toggleMenuDialog()}
			>
				Cancel
			</Button>
		</Flex>,
		"Menu",
	);
	return [toggleMenuDialog, menuDialog] as const;
};

const usePODDialog = (onSubmit: (pod: Blob) => void) => {
	const [togglePODDialog, podDialog] = useDialog(
		<POD
			onSubmit={(pod) => {
				onSubmit(pod);
				togglePODDialog();
			}}
			onCancel={() => togglePODDialog()}
		/>,
		"Proof of Delivery",
	);
	return [togglePODDialog, podDialog] as const;
};

const ExpansionPanelTitle = ({
	collectionDate,
	deliveryDate,
	customerName,
	id,
	uniqueId,
	status,
}: ViewLegsForDriverResponse) => {
	const collectionDateString = useMemo(
		() => toDatetimeString(collectionDate),
		[collectionDate],
	);
	const deliveryDateString = useMemo(
		() => toDatetimeString(deliveryDate),
		[deliveryDate],
	);
	const customerNameString = customerName ?? "N/A";
	const statusString = legStatusTypeNames[status] ?? "N/A";
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
			}}
		>
			<div>
				<div>{uniqueId ?? id}</div>
				<div>
					{collectionDateString} - {deliveryDateString}
				</div>
				<div>{customerNameString}</div>
			</div>
			<div>
				<div>{statusString}</div>
			</div>
		</div>
	);
};

const leftRight: CSSProperties = {
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	gap: "20px",
};

const right: CSSProperties = {
	textAlign: "right",
};

type LegActionButtonProps = {
	status: LegStatusType;
	canChangeToNextStatus: boolean;
	onClick: () => void;
};
const LegActionButton = ({
	status,
	canChangeToNextStatus,
	onClick,
}: LegActionButtonProps) => {
	const text = useMemo(() => {
		if (status === LegStatusType.Planned) return "Accept";
		if (status === LegStatusType.Accepted) return "Start";
		if (status === LegStatusType.InStartPosition) return "Collect";
		if (status === LegStatusType.Underway) return "Deliver";
		if (status === LegStatusType.InEndPosition) return "Complete";
		return "Change status";
	}, [status]);
	return (
		<Button disabled={!canChangeToNextStatus} onClick={onClick}>
			{text}
		</Button>
	);
};

const StyledWideButton = styled.div`
	width: 100%;
	margin-top: 20px;
	> button {
		width: 100%;
	}
`;

const useNow = () => {
	const [now, setNow] = useState(new Date());
	useEffect(() => {
		const interval = setInterval(() => setNow(new Date()), 1000);
		return () => clearInterval(interval);
	}, []);
	return now;
};

type PODProps = {
	onSubmit: (pod: Blob) => void;
	onCancel?: () => void;
};
const POD = ({ onSubmit, onCancel }: PODProps) => {
	const [name, setName] = useState("");
	const now = useNow();
	const signaturePadRef = useRef<SignaturePad>(null);
	const printRef = useRef<HTMLDivElement>(null);
	const handleSubmit = async () => {
		const isEmpty = signaturePadRef.current?.isEmpty() ?? true;
		const element = printRef.current;
		if (name === "") return toast.error("Name is required");
		if (isEmpty) return toast.error("Signature is required");
		if (!element) return toast.error("The element is not ready");
		const canvas = await html2canvas(element);
		const data = canvas.toDataURL("image/png");
		onSubmit(dataURLtoBlob(data));
	};
	const handleClear = () => {
		signaturePadRef.current?.clear();
		setName("");
	};
	return (
		<div style={{ width: "100%" }}>
			<div ref={printRef}>
				<div>Proof of Delivery</div>
				<div>
					<div>
						<div>Date</div>
						<div>{now.toUTCString()}</div>
					</div>
					<div>
						<div>Name</div>
						<div>
							<Input value={name} onChange={(e) => setName(e.value)} />
						</div>
					</div>
					<div>
						<div>Signature</div>
						<div
							style={{
								width: "100%",
								border: "1px solid #9d9dab",
								borderRadius: "4px",
							}}
						>
							<SignaturePad ref={signaturePadRef} />
						</div>
					</div>
				</div>
			</div>
			<div>
				<Button onClick={handleClear}>Clear</Button>
				<Button onClick={handleSubmit}>Submit</Button>
				<Button onClick={onCancel}>Cancel</Button>
			</div>
		</div>
	);
};
function dataURLtoBlob(dataurl: string) {
	const arr = dataurl.split(",");
	const arr0 = arr[0];
	const arr1 = arr[1];
	if (!arr0 || !arr1) throw new Error("Invalid data URL");
	const mime = arr0.match(/:(.*?);/)?.[1];
	const bstr = atob(arr1);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new Blob([u8arr], { type: mime });
}

export const HomePage = () => {
	useTitle("Home Page");
	const auth = useStore(authStore);
	const username = `${auth?.user?.userFirstName ?? ""} ${
		auth?.user?.userLastName ?? ""
	}`.trim();
	const _legs = useQuery({
		queryKey: ["jobApi.leg.legV2List"],
		queryFn: () => jobApi.leg.legForDriverList().then((x) => x.data),
		initialData: [],
		refetchInterval: 1000 * 3, // 3 seconds
	});
	const [toggleMenuDialog, menuDialog] = useMenuDialog();
	const [expandedLegId, setExpandedLegId] = useState<number>();
	const [togglePODDialog, podDialog] = usePODDialog(async (blob) => {
		if (!expandedLegId) return;
		const files = [
			new File([blob], `pod-leg-${expandedLegId}-${Date.now()}.png`, {
				type: blob.type,
			}),
		];
		await toasted(
			uploadDocuments(files, expandedLegId, DocumentUsage.Leg, true).then(() =>
				_legs.refetch(),
			),
			"Uploading POD",
		);
	});
	const leg = useMemo(
		() => _legs.data.find((x) => x.id === expandedLegId),
		[_legs.data, expandedLegId],
	);
	const canChangeToNextStatus = useMemo(() => {
		const status = leg?.status;
		if (!status) return false;
		const nextStatus = (status + 1) as LegStatusType;
		if (!leg.availableStatuses?.includes(nextStatus)) return false;
		return true;
	}, [leg?.status, leg?.availableStatuses]);

	const handleStatusForward = async () => {
		const status = leg?.status;
		if (!status) return;
		if (!expandedLegId) return;
		const nextStatus = (status + 1) as LegStatusType;
		if (!leg.availableStatuses?.includes(nextStatus)) return;
		await toasted(
			jobApi.leg
				.legChangeStatusCreate({
					legId: expandedLegId,
					status: nextStatus,
				})
				.then(() => _legs.refetch()),
			"Changing status",
		);
	};

	return (
		<StyledDiv>
			{menuDialog}
			{podDialog}
			<div style={leftRight}>
				<h1>Work assigned</h1>
				{username && <h3>({username})</h3>}
				<Icon
					name="reorder"
					style={{ zoom: 3, cursor: "pointer" }}
					onClick={toggleMenuDialog}
				/>
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					gap: "20px",
				}}
			>
				{!_legs.data.length && <h1>You don't have any work assigned.</h1>}

				{_legs.data
					.sort((a, b) => {
						if (a.collectionDate && b.collectionDate) {
							return dayjs(a.collectionDate).diff(dayjs(b.collectionDate));
						}
						if (!a.collectionDate) {
							return 1;
						}
						return -1;
					})
					.map((leg) => {
						const expanded = leg.id === expandedLegId;
						return (
							<ExpansionPanel
								expanded={expanded}
								onAction={() =>
									setExpandedLegId((x) => (x === leg.id ? undefined : leg.id))
								}
								key={leg.id}
								title={<ExpansionPanelTitle {...leg} />}
							>
								<Reveal>
									{expanded && (
										<ExpansionPanelContent>
											<h3>Requested</h3>
											<div style={leftRight}>
												<div>
													<Icon name="cart" />
													<b>{leg.truckName ?? "N/A"}</b>
												</div>
												<div style={right}>
													<b>{leg.trailerName ?? "N/A"}</b>
													<Icon name="cart" />
												</div>
											</div>
											<h3>Location</h3>
											<div style={leftRight}>
												<div>
													<Icon name="marker-pin" />
													<b>{leg.collectionLocationName ?? "N/A"}</b>
												</div>
												<div style={right}>
													<b>{leg.deliveryLocationName ?? "N/A"}</b>
													<Icon name="marker-pin" />
												</div>
											</div>
											<div style={leftRight}>
												<div>
													<Icon name="marker-pin-target" />
													<b>{leg.collectionLocationAddress ?? "N/A"}</b>
												</div>
												<div style={right}>
													<b>{leg.deliveryLocationAddress ?? "N/A"}</b>
													<Icon name="marker-pin-target" />
												</div>
											</div>
											{leg.containerNumber && (
												<>
													<h3>Container</h3>
													<div>
														<div>
															<Icon name="pin" />
															Number: <b>{leg.containerNumber}</b>
														</div>
														<div>
															<Icon name="pin" />
															Pin: <b>{leg.containerPin ?? "N/A"}</b>
														</div>
														<div>
															<Icon name="pin" />
															Seal number:{" "}
															<b>{leg.containerSealNumber ?? ""}</b>
														</div>
														<div>
															<Icon name="book" />
															Notes: <b>{leg.notes ?? "N/A"}</b>
														</div>
													</div>
												</>
											)}
											{leg.goods.length > 0 && (
												<>
													<h3>Goods</h3>
													{leg.goods.map((good) => (
														<div key={good.id} style={leftRight}>
															<div>
																<Icon name="select-box" />
																<b>{good.name}</b>
															</div>
															<div style={right}>
																<b>{good.quantity}</b>
																<Icon name="circle" />
															</div>
														</div>
													))}
												</>
											)}
											{leg.loadGoods.length > 0 && (
												<>
													<h3>Goods (from Load)</h3>
													{leg.loadGoods.map((good) => (
														<div key={good.id} style={leftRight}>
															<div>
																<Icon name="select-box" />
																<b>{good.name}</b>
															</div>
															<div style={right}>
																<b>{good.quantity}</b>
																<Icon name="circle" />
															</div>
														</div>
													))}
												</>
											)}
											<StyledWideButton>
												{leg.status === LegStatusType.InEndPosition && (
													<>
														<Button onClick={togglePODDialog}>
															Add POD ({leg.podCount})
														</Button>
														<Button
															onClick={() => {
																const fileUpload =
																	document.querySelector<HTMLInputElement>(
																		`#${leg.uniqueId ?? ""}`,
																	);
																if (!fileUpload) return;
																fileUpload.value = "";
																fileUpload.click();
															}}
														>
															Add Photo POD
														</Button>
														<form onSubmit={() => false}>
															<input
																id={leg.uniqueId ?? undefined}
																onChange={(e) => {
																	const files = e.target.files;
																	if (!files) return;
																	const file = files[0];
																	if (!file) return;
																	const extension = file.name.split(".").pop();
																	const newName = `${leg.uniqueId}-${Date.now()}.${extension}`;
																	const newFile = new File([file], newName, {
																		type: file.type,
																	});
																	toasted(
																		uploadDocuments(
																			[newFile],
																			leg.id,
																			DocumentUsage.Leg,
																			true,
																		).then(() => _legs.refetch()),
																		"Uploading photo",
																	);
																}}
																accept="image/*"
																type="file"
																hidden
															/>
														</form>
													</>
												)}
												<LegActionButton
													status={leg.status}
													canChangeToNextStatus={canChangeToNextStatus}
													onClick={handleStatusForward}
												/>
											</StyledWideButton>
										</ExpansionPanelContent>
									)}
								</Reveal>
							</ExpansionPanel>
						);
					})}
			</div>
		</StyledDiv>
	);
};
