import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { type InferType, object, string } from "yup";
import { InputPassword, InputText } from "./GenericForm";

const LoginSchema = object({
	username: string().email().required().label("Email"),
	password: string().required().label("Password"),
});

type Login = InferType<typeof LoginSchema>;

type LoginFormProps = {
	onSubmit: (data: Login) => Promise<unknown>;
};
export const LoginForm = ({ onSubmit }: LoginFormProps) => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { handleSubmit, control, getValues } = useForm<Login>({
		resolver: yupResolver(LoginSchema),
		defaultValues: { username: "", password: "" },
	});
	return (
		<form
			className="k-form"
			onSubmit={handleSubmit(async (x) => {
				setLoading(true);
				await onSubmit(x).finally(() => setLoading(false));
			})}
		>
			<InputText control={control} schema={LoginSchema} name="username" />
			<InputPassword control={control} schema={LoginSchema} name="password" />
			<p style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
				<Button
					type="button"
					onClick={() =>
						navigate("/forgotten-password", {
							state: { email: getValues("username") },
						})
					}
				>
					Forgot password
				</Button>
				<Button
					type="submit"
					themeColor="success"
					icon={loading ? "loading" : undefined}
					disabled={loading}
				>
					Log in
				</Button>
			</p>
		</form>
	);
};
