import type { ReactNode } from "react";
import styled from "styled-components";
import image from "../assets/images/UKTruck.png";

const StyledLayout = styled.div`
.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 307px;
  background: url(${image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  margin: 40px 24px;
}
`;

type DriverLayoutProps = {
	children: ReactNode;
};
export const DriverLayout = ({ children }: DriverLayoutProps) => (
	<StyledLayout>
		<div className="root">
			<div className="header" />
			<div className="content">{children}</div>
		</div>
	</StyledLayout>
);
